import React, {useState, useContext, useEffect} from 'react';
import chimera from '../../chimera';
import FormFieldMicro from '../FormFieldMicro';
import BannerContext, {BannerLog} from '../BannerLogContext';
import Modal, {choiceCancel, choiceDelete} from '../Modal';
import LoadingSpinner from '../LoadingSpinner';
import ModalContext from '../ModalContext';
import Autocomplete from '../Autocomplete';
import AlertsContext from '../AlertsContext';
import ExpandableDropdown from '../ExpandableDropdown';
import UserContext from '../../UserContext';

const DEFAULT_ITEM = {
    description: '',
    sku: '',
    qty: 0,
    estimatedUnitCost: '',
    shippingCost: '',
    link: ''
}

const DEFAULT_ORDER = {
    customer: {
        displayName: '',
        chimeraId: '',
        qbId: ''
    },
    vendor: '',
    ticketNumber: '',
    qbPurchaseOrderNumber: '',
    qbPurchaseOrderId: '',
    qbEstimateNumber: '',
    qbEstimateId: '',
    qbInvoiceNumber: '',
    qbInvoiceId: '',
    vendorBillNumber: '',
    vendorBillPaid: false,
    vendorOrderNumber: '',
    trackingNumber: '',
    status: 'New',
    type: 'New',
    customerQuoted: false,
    recurringCharge: false,
    billable: false,
    datePurchased: '',
    eta: '',
    items: [DEFAULT_ITEM],
    notes: '',
}

const ItemArea = (props) => {
    const [clipboardIcon, setClipboardIcon] = useState("fas fa-clipboard");
    const [calculatedTotal, setCalculatedTotal] = useState("");

    useEffect(() => {
        let unitCostStr = props.order.items[props.itemIndex].estimatedUnitCost;
        let shippingCostStr = props.order.items[props.itemIndex].shippingCost ? props.order.items[props.itemIndex].shippingCost : '';
        if(unitCostStr.startsWith('$')) unitCostStr = unitCostStr.substring(1).trim();
        if(shippingCostStr.startsWith('$')) shippingCostStr = shippingCostStr.substring(1).trim();
        const unitCost = parseFloat(unitCostStr);
        const shippingCost = shippingCostStr === "" ? 0 : parseFloat(shippingCostStr);
        if(unitCost.toString() === "NaN" || shippingCost.toString() === "NaN") {
            setCalculatedTotal("");
        }
        else {
            const total = (props.order.items[props.itemIndex].qty * unitCost) + shippingCost;
            setCalculatedTotal(`$${total.toFixed(2)}`);
        }
    }, [props.order.items[props.itemIndex].qty, props.order.items[props.itemIndex].estimatedUnitCost, props.order.items[props.itemIndex].shippingCost]);

    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;

        let newOrder = JSON.parse(JSON.stringify(props.order));

        if(name === "calculatedTotal") {

        }
        else if(event.target.type === "number") {
            chimera.setAttr(newOrder, name, parseFloat(value));
        }
        else {
            chimera.setAttr(newOrder, name, value);
        }

        props.updateOrderAtIndex(props.index, newOrder);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const openLinkInNewTab = (e) => {
        e.preventDefault();
        const link = props.order.items[props.itemIndex].link;
        window.open(link, '_blank');
    }

    const copyLinkToClipboard = (e) => {
        e.preventDefault();
        setClipboardIcon('fas fa-check');
        const link = props.order.items[props.itemIndex].link;
        navigator.clipboard.writeText(link);
        setTimeout(() => {
            setClipboardIcon('fas fa-clipboard');
        }, 500);
    }

    return(
        <div className={`section-outline${props.itemIndex > 0 ? ' mt-2' : ''}`}>
            <h5 className="text-start">Item {props.itemIndex + 1}{props.showDelete ? <>&nbsp;<button className="btn btn-sm btn-danger" onClick={(e) => {e.preventDefault(); props.removeItemAtIndex(props.itemIndex)}}><i className="fas fa-times"/></button></>:null}</h5>
            {!props.isEstimate ? 
            <div className="row">
                <FormFieldMicro
                    type="text"
                    name={`items[${props.itemIndex}].description`}
                    label="Description"
                    value={props.order.items[props.itemIndex].description}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.locked}
                    fullWidth
                />
            </div>
            :null}
            <div className="row">
                {!props.isEstimate ? 
                <FormFieldMicro
                    type="text"
                    name={`items[${props.itemIndex}].sku`}
                    label="SKU"
                    value={props.order.items[props.itemIndex].sku}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.locked}
                    size={17}
                    fit
                />
                :null}
                <FormFieldMicro
                    type="number"
                    name={`items[${props.itemIndex}].qty`}
                    label="QTY"
                    value={props.order.items[props.itemIndex].qty}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.locked}
                    size={3}
                    required
                    min={1}
                    fit
                />
                {!props.isEstimate ? 
                <>
                <FormFieldMicro
                    type="text"
                    name={`items[${props.itemIndex}].estimatedUnitCost`}
                    label="Est. Unit Cost"
                    value={props.order.items[props.itemIndex].estimatedUnitCost}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.locked}
                    size={10}
                    placeholder={"$0.00"}
                    pattern={"\\$?[\\d\\,]+\\.\\d\\d"}
                    fit
                />
                <FormFieldMicro
                    type="text"
                    name="calculatedTotal"
                    label="Est. Total Cost"
                    value={calculatedTotal}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled
                    size={10}
                    fit
                />
                </>
                :null}
                <FormFieldMicro
                    type="text"
                    name={`items[${props.itemIndex}].link`}
                    label="Link"
                    value={props.order.items[props.itemIndex].link}
                    handleChange={handleChange}
                    onBlur={trimOnBlur}
                    disabled={props.locked || !props.linkRequired}
                    size={30}
                    required={props.linkRequired}
                    fullWidth
                >
                    <div className="tooltip-container border-0">
                        <span className="tooltip-text-bottom">
                            Open Link in New Tab
                        </span>
                        <button className="btn btn-secondary btn-sm mt-1" onClick={openLinkInNewTab}>
                            <i className="fas fa-up-right-from-square"/>
                        </button>
                    </div>
                    <div className="tooltip-container border-0">
                        <span className="tooltip-text-bottom">
                            Copy Link to Clipboard
                        </span>
                        <button className="btn btn-secondary btn-sm ms-1 mt-1" onClick={copyLinkToClipboard} disabled={clipboardIcon === "fas fa-check"}>
                            <i className={clipboardIcon}/>
                        </button>
                    </div>
                </FormFieldMicro>
            </div>
        </div>
    )
}

const OrderForm = (props) => {
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    
    const handleChange = (event) => {
        if(event.target.type !== "checkbox") event.preventDefault();
        props.setModified(true);
        const name = event.target.name;
        const value = event.target.type !== "checkbox" ? event.target.value : event.target.checked;

        let newOrder = JSON.parse(JSON.stringify(props.order));

        if(name === "customer.displayName") {
            newOrder.customer.qbId = 'NOT SET';
            newOrder.customer.chimeraId = 'NOT SET';
        }
        
        if(event.target.type === "number") {
            chimera.setAttr(newOrder, name, parseFloat(value));
        }
        else {
            chimera.setAttr(newOrder, name, value);
        }

        if(name === "type" && value === "From Stock") {
            for(const item of newOrder.items) {
                item.link = '(None)';
            }
        }

        props.updateOrderAtIndex(props.index, newOrder);
    }

    const trimOnBlur = (event) => {
        handleChange({
            target: {
                type: "string",
                name: event.target.name,
                value: event.target.value.trim()
            },
            preventDefault: () => {}
        })
    }

    const addItem = (e) => {
        e.preventDefault();
        const newOrder = JSON.parse(JSON.stringify(props.order));
        newOrder.items = [...props.order.items, DEFAULT_ITEM];
        props.updateOrderAtIndex(props.index, newOrder);
    }

    const removeItemAtIndex = (index) => {
        const newOrder = JSON.parse(JSON.stringify(props.order));
        newOrder.items = props.order.items.filter((_, i) => i !== index);
        props.updateOrderAtIndex(props.index, newOrder);
    }

    const blameAuthorStr = (blame) => {
        return `${blame.first} ${blame.last} (${blame.email})`;
    }

    const suggestionChosenCallback = (suggestion) => {
        // find the matching customer and plug in its info
        const customer = props.customers.find((customer) => customer.displayName === suggestion);
        if(customer) {
            // change Customer for ALL orders
            for(let i = 0; i < props.workingOrders.length; i++) {
                const newOrder = JSON.parse(JSON.stringify(props.workingOrders[i]));
                newOrder.customer = {
                    displayName: customer.displayName,
                    qbId: customer.integrationIds.quickbooks,
                    chimeraId: customer._id
                }
                props.updateOrderAtIndex(i, newOrder);
            }
        }
        else {
            banners.addBanner('danger', 'Something went wrong with selecting the customer.', 'Error');
        }
    }

    const openPurchaseOrderInNewTab = (e) => {
        e.preventDefault();
        window.open(`https://app.qbo.intuit.com/app/purchaseorder?txnId=${props.order.qbPurchaseOrderId}`, '_blank');
    }

    const openEstimateInNewTab = (e) => {
        e.preventDefault();
        window.open(`https://app.qbo.intuit.com/app/estimate?txnId=${props.order.qbEstimateId}`, '_blank');
    }

    const openInvoiceInNewTab = (e) => {
        e.preventDefault();
        window.open(`https://app.qbo.intuit.com/app/invoice?txnId=${props.order.qbInvoiceId}`, '_blank');
    }

    return (
        <div>
            {props.showDelete ? 
            <div className="row">
                <div className="col">
                    <button className="btn btn-danger btn-sm" onClick={(e) => {e.preventDefault(); props.removeOrderAtIndex(props.index)}} disabled={props.isSaving}>
                        <i className="fas fa-times"/>
                    </button>
                </div>
            </div>
            :null}
            <div className="row">
                <div className="col">
                    <div className='section-outline'>
                        <div className="row">
                            <FormFieldMicro
                                type="text"
                                name="ticketNumber"
                                label="Ticket #"
                                value={props.order.ticketNumber}
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                                disabled={props.locked}
                                size={6}
                                required
                                fit
                            />
                            {!props.isEstimate ? 
                            <>
                            <FormFieldMicro
                                type="text"
                                name="qbPurchaseOrderNumber"
                                label="QB PO #"
                                value={props.order.qbPurchaseOrderNumber}
                                disabled
                                size={6}
                                fit
                            >
                                {props.order.qbPurchaseOrderId ? 
                                    <div className="tooltip-container border-0">
                                        <span className="tooltip-text-bottom">
                                            Open QB PO in New Tab
                                        </span>
                                        <button className="btn btn-secondary btn-sm mt-1" onClick={openPurchaseOrderInNewTab}>
                                            <i className="fas fa-up-right-from-square"/>
                                        </button>
                                    </div>
                                :null}
                            </FormFieldMicro>
                            <FormFieldMicro
                                type="text"
                                name="qbEstimateNumber"
                                label="QB Estimate #"
                                value={props.order.qbEstimateNumber}
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                                disabled={props.locked || props.order.qbPurchaseOrderNumber !== ""}
                                size={6}
                                fit
                            >
                                {props.order.qbEstimateId ? 
                                    <div className="tooltip-container border-0">
                                        <span className="tooltip-text-bottom">
                                            Open QB Estimate in New Tab
                                        </span>
                                        <button className="btn btn-secondary btn-sm mt-1" onClick={openEstimateInNewTab}>
                                            <i className="fas fa-up-right-from-square"/>
                                        </button>
                                    </div>
                                :null}
                            </FormFieldMicro>
                            <FormFieldMicro
                                type="text"
                                name="qbInvoiceNumber"
                                label="QB Invoice #"
                                value={props.order.qbInvoiceNumber}
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                                disabled={props.locked}
                                size={6}
                                fit
                            >
                                {props.order.qbInvoiceId ? 
                                    <>
                                    <div className="tooltip-container border-0">
                                        <span className="tooltip-text-bottom">
                                            Open QB Invoice in New Tab
                                        </span>
                                        <button className="btn btn-secondary btn-sm mt-1" onClick={openInvoiceInNewTab}>
                                            <i className="fas fa-up-right-from-square"/>
                                        </button>
                                    </div>
                                    &nbsp;Status: {props.order.qbInvoiceStatus !== "NOT SET" ? props.order.qbInvoiceStatus : "(Check QB)"}
                                    </>
                                :null}
                            </FormFieldMicro>
                            <FormFieldMicro
                                type="text"
                                name="bundleNumber"
                                label="PO #"
                                value={props.order.bundleNumber}
                                disabled
                                size={6}
                                fit
                            />
                            <FormFieldMicro
                                type="text"
                                name="vendorOrderNumber"
                                label="Vendor Order #"
                                value={props.order.vendorOrderNumber}
                                size={6}
                                handleChange={handleChange}
                                disabled={props.locked}
                                fit
                            />
                            <FormFieldMicro
                                type="text"
                                name="trackingNumber"
                                label="Tracking #"
                                value={props.order.trackingNumber}
                                handleChange={handleChange}
                                disabled={props.locked}
                                fit
                            />
                            <FormFieldMicro
                                type="date"
                                name="datePurchased"
                                label="Date Purchased"
                                value={props.order.datePurchased}
                                handleChange={handleChange}
                                disabled={props.locked}
                                fit
                            />
                            <FormFieldMicro
                                type="date"
                                name="eta"
                                label="ETA"
                                value={props.order.eta}
                                handleChange={handleChange}
                                disabled={props.locked}
                                fit
                            />
                            </>
                            :null}
                        </div>
                        <div className="row">
                            <Autocomplete
                                label="Customer"
                                value={props.order.customer.displayName}
                                suggestions={props.customers.map(customer => customer.displayName)}
                                suggestionChosenCallback={suggestionChosenCallback}
                                strictMode
                                required
                                disabled={props.locked || props.index > 0}
                            />
                            <FormFieldMicro
                                type="component"
                                name="vendor"
                                label="Vendor"
                                required
                                fit
                            >
                                <ExpandableDropdown 
                                    listName="PRODUCT_ORDER_VENDORS"
                                    htmlName="vendor"
                                    value={props.order.vendor}
                                    handleChange={handleChange}
                                    required
                                    disabled={props.locked}
                                    banners={banners}
                                    modalContext={modaling}
                                />
                            </FormFieldMicro>
                            {!props.isEstimate ? 
                            <FormFieldMicro
                                type="select"
                                name="type"
                                label="Type"
                                value={props.order.type}
                                handleChange={handleChange}
                                options={[
                                    {id: "New", value: "New"},
                                    {id: "From Stock", value: "From Stock"},
                                    {id: "Return", value: "Return"},
                                    {id: "Disconnect", value: "Disconnect"},
                                ]}
                                disabled={props.locked}
                                excludeNoneSelected
                                fit
                            />
                            :null}
                            <FormFieldMicro
                                type="select"
                                name="status"
                                label="Status"
                                value={props.order.status}
                                handleChange={handleChange}
                                options={[
                                    {id: "New", value: "Pending Estimate"},
                                    {id: "Estimate", value: "Estimate"},
                                    {id: "Approved", value: "Approved"},
                                    {id: "Ordered", value: "Ordered"},
                                    {id: "Pending", value: "Pending"},
                                    {id: "Ready to Pay Vendor", value: "Ready to Pay Vendor"},
                                    {id: "Received", value: "Received"},
                                    {id: "Backordered", value: "Backordered"},
                                    {id: "Cancelled", value: "Cancelled"},
                                    {id: "Completed", value: "Completed"},
                                    {id: "Disconnect", value: "Disconnect"},
                                ]}
                                excludeNoneSelected
                                disabled={props.isSaving}
                                fit
                            />
                        </div>
                        {!props.isEstimate ? 
                        <div className="row">
                            <FormFieldMicro
                                type="select"
                                name="customerQuoted"
                                label="Customer Quoted?"
                                value={props.order.customerQuoted}
                                handleChange={handleChange}
                                options={[
                                    {id: true, value: "Yes"}, // `id` is the value and `value` is the label. this is bad, please fix later
                                    {id: false, value: "No"},
                                ]}
                                excludeNoneSelected
                                disabled={props.locked}
                                fit
                            />
                            <FormFieldMicro
                                type="select"
                                name="billable"
                                label="Billable?"
                                value={props.order.billable}
                                handleChange={handleChange}
                                options={[
                                    {id: true, value: "Yes"}, // `id` is the value and `value` is the label. this is bad, please fix later
                                    {id: false, value: "No"},
                                ]}
                                excludeNoneSelected
                                disabled={props.locked}
                                fit
                            />
                            <FormFieldMicro
                                type="select"
                                name="recurringCharge"
                                label="Recurring Charge?"
                                value={props.order.recurringCharge}
                                handleChange={handleChange}
                                options={[
                                    {id: true, value: "Yes"}, // `id` is the value and `value` is the label. this is bad, please fix later
                                    {id: false, value: "No"},
                                ]}
                                excludeNoneSelected
                                disabled={props.locked}
                                fit
                            />
                            {props.order.status !== "New" && props.order.status !== "Cancelled" && props.order.status !== "Disconnect" ? 
                            <>
                            <FormFieldMicro
                                type="text"
                                name="vendorBillNumber"
                                label="Vendor Bill #"
                                value={props.order.vendorBillNumber}
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                                disabled={props.isSaving}
                                size={6}
                                fit
                            />
                            <FormFieldMicro
                                type="select"
                                name="vendorBillPaid"
                                label="Vendor Bill Paid?"
                                value={props.order.vendorBillPaid}
                                handleChange={handleChange}
                                options={[
                                    {id: true, value: 'Yes'},
                                    {id: false, value: 'No'},
                                ]}
                                disabled={props.isSaving}
                                excludeNoneSelected
                                fit
                            />
                            <FormFieldMicro
                                type="text"
                                name="shippingCost"
                                label="Shipping Total"
                                value={props.order.shippingCost}
                                handleChange={handleChange}
                                onBlur={trimOnBlur}
                                disabled={props.locked}
                                size={10}
                                placeholder={"$0.00"}
                                pattern={"\\$?[\\d\\,]+\\.\\d\\d"}
                                fit
                            />
                            </>
                            :null}
                        </div>
                        :null}
                        {props.order.items.map((_, i) => <div key={i} className="row">
                            <div className="col">
                                <ItemArea {...props} itemIndex={i} removeItemAtIndex={removeItemAtIndex} showDelete={props.order.items.length > 1 && !props.locked} linkRequired={props.order.type !== 'From Stock'}/>
                            </div>
                        </div>)}
                        <div className="row mt-2">
                            <div className="col d-flex flex-column">
                                <button className="btn btn-success w-fit" onClick={addItem} disabled={props.locked}>
                                    <i className="fas fa-plus"/>&nbsp;Add Item
                                </button>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col">
                                <FormFieldMicro
                                    type="textarea"
                                    name="notes"
                                    label="Notes"
                                    value={props.order.notes}
                                    handleChange={handleChange}
                                    disabled={props.isSaving}
                                    resize="vertical"
                                    placeholder={"Example:\nDifferent shipping address to customer; ETA of product delivery; Customer returned or cancelled an item; any other pertinent information"}
                                    fullWidth
                                />
                            </div>
                        </div>
                        {props.saveMode === "PUT" && props.order.blame ? 
                        <div className="row">
                            <div className="col text-end text-muted">
                                <span>Created by {blameAuthorStr(props.order.blame.createdBy)} at {(new Date(props.order.createdAt)).toLocaleString()}</span><br/>
                                <span>Last modified by {blameAuthorStr(props.order.blame.modifiedBy)} at {(new Date(props.order.updatedAt)).toLocaleString()}</span>
                            </div>
                        </div>
                        :null}
                    </div>
                </div>
            </div>
        </div>
    )
}

const ProductOrderFormBody = props => {
    const banners = useContext(BannerContext);
    const modaling = useContext(ModalContext);
    const userContext = useContext(UserContext);

    const handleSaveError = (err) => {
        console.error(err);
        if(err.createdProductOrder) {
            console.error(err.err);
            if(props.parentBanners) {
                props.parentBanners.addBanner('danger', 'The Purchase Order was successfully saved, but the QuickBooks PO could not be created.', 'Error');
                if(props.onClose) props.onClose();
                modaling.backtrack();
            }
            else {
                banners.addBanner('danger', 'The Purchase Order was successfully saved, but the QuickBooks PO could not be created. Please close this form to prevent additional errors.', 'Error');
            }
        }
        else if(err.status === 404) {
            if(err.details.message) {
                banners.addBanner('danger', err.details.message, '404 Error');
            }
            else {
                console.error(err.details);
                banners.addBanner('danger', '', '404 Error');
            }
        }
        else if(err.details && err.details.name === "ValidationError") {
            for(const key in err.details.errors) {
                banners.addBanner('danger', err.details.errors[key].message, 'Validation Error');
            }
        }
        else if(err.details && err.details.Fault) {
            for(const e of err.details.Fault.Error) {
                if(e.Detail === "Business Validation Error: Select an account for this transaction.") {
                    banners.addBanner('danger', 'The provided Estimate has invalid items for Purchase Orders. Check the box that says "I purchase this product/service from a vendor" when editing the Product/Service.', 'QuickBooks Error');
                }
                else {
                    banners.addBanner('danger', e.Detail, 'QuickBooks Error');
                }
            }
        }
        else if(err.err && err.err.details) {
            for(const e of err.err.details.Fault.Error) {
                if(e.Detail === "Business Validation Error: Select an account for this transaction.") {
                    banners.addBanner('danger', 'The provided Estimate has invalid items for Purchase Orders. Check the box that says "I purchase this product/service from a vendor" when editing the Product/Service.', 'QuickBooks Error');
                }
                else {
                    banners.addBanner('danger', e.Detail, 'QuickBooks Error');
                }
            }
        }
        else if(err.name !== 'AbortError') {
            banners.addBanner('danger', 'Failed to save order', 'Error');
        }
        props.setIsSaving(false);
    }

    const handleSave = (event) => {
        if(event) event.preventDefault();
        const resolve = (savedOrders) => {
            if(props.saveMode === 'PUT') {
                if(props.parentBanners) props.parentBanners.addBanner('info', `Changes saved successfully.`, 'Saved');
            }
            else { // saveMode === 'POST'
                if(props.parentBanners) props.parentBanners.addBanner('info', `Product Order${savedOrders.length > 1 ? 's' : ''} for ${savedOrders[0].customer.displayName} ${savedOrders.length > 1 ? 'were' : 'was'} created successfully.`, 'Success');
                props.setSaveMode('PUT');
            }
            props.setWorkingOrders(savedOrders);
            props.setSavedOrders(savedOrders);
            props.setIsSaving(false);
            props.onClose();
            modaling.setModal(null);

            let order = savedOrders.find(order => order.status === "Ready to Pay Vendor");
            if(order && !chimera.isDeveloperMode()) {
                chimera.callAPI(undefined, '/api/sendmail2', 'POST', {
                    from: 'DEFAULT',
                    mail: {
                        to: 'accounting@gocbit.com',
                        subject: `Ready to Pay Vendor: Order ${order.bundleNumber} for ${order.customer.displayName} was set to Ready to Pay Vendor`,
                        text: `Order ${order.bundleNumber} for ${order.customer.displayName} was saved with the status set to 'Ready to Pay Vendor.' Please pay the vendor and update the order's status accordingly. The order can be found here: https://bms.gocbit.com/orders/products/${order._id}`
                    }
                })
                .catch(e => {
                    console.error(e);
                    alert(`Error: Because Order ${order.bundleNumber} for ${order.customer.displayName} was saved as "Ready to Pay Vendor", Chimera tried to email accounting@gocbit.com but was unsuccessful. Please alert accounting about the Order, and please notify the developer about this issue.`);
                })
            }
        }
        props.handleSave()
        .then(response => resolve(response))
        .catch(err => handleSaveError(err));
    }

    const updateOrderAtIndex = (index, newOrder) => {
        props.setWorkingOrders(props.workingOrders.map((order, i) => i === index ? newOrder : order));
    }

    const addNewOrder = (e) => {
        e.preventDefault();
        const orderToAdd = DEFAULT_ORDER;
        orderToAdd.customer = props.workingOrders[0].customer;
        props.setWorkingOrders([...props.workingOrders, orderToAdd]);
    }

    const removeOrderAtIndex = (index) => {
        props.setWorkingOrders(props.workingOrders.filter((_, i) => i !== index));
    }

    const checkLocked = (order) => {
        if(userContext.permissions.admin.write) return false;
        if(props.saveMode === 'POST') return false;
        if(props.isSaving) return true;
        // if the saved order is Received or Completed, lock it
        const savedOrder = props.savedOrders.find((_order) => _order._id === order._id);
        if(!savedOrder) return false;
        return (savedOrder.status === "Received" || savedOrder.status === "Completed");
    }

    return(
        <div>
            {props.workingOrders.map((order, i) => 
                <div className="row my-2" key={i}>
                    <div className="col">
                        <OrderForm isEstimate={props.isEstimate} order={order} index={i} workingOrders={props.workingOrders} customers={props.customers} updateOrderAtIndex={updateOrderAtIndex} removeOrderAtIndex={removeOrderAtIndex} showDelete={props.workingOrders.length > 1} isSaving={props.isSaving} setModified={props.setModified} saveMode={props.saveMode} locked={checkLocked(order)}/>
                    </div>
                </div>
            )}
            {props.saveMode === 'POST' ? 
            <div className="row mb-2">
                <div className="col">
                    <button className="btn btn-success" onClick={addNewOrder} disabled={props.isSaving}>
                        <i className="fas fa-plus"/>&nbsp;Add Order
                    </button>
                </div>
            </div>
            :null}
            <div className="row mb-2">
                <div className="col">
                    {props.choices.length > 1 ? 
                    <button className="btn btn-danger float-start" onClick={props.choices[1].func} disabled={props.isSaving}>
                        <i className="fas fa-times"/>&nbsp;Delete Permanently
                    </button>
                    :null}
                    <button type="submit" className="btn btn-primary float-end" onClick={handleSave} disabled={props.saveDisabled}>
                        <i className={`${props.isSaving ? 'fas fa-spinner' : 'fas fa-floppy-disk'}`}/>&nbsp;{props.isSaving ? props.savingLabel : 'Save & Close'}
                    </button>
                    {!props.isEstimate ? 
                    <button className="btn btn-success me-2 float-end" onClick={props.handleGenerate} disabled={props.isGenerating || props.saveMode === 'POST'}>
                        <i className={props.isGenerating ? 'fas fa-spinner' : 'fas fa-plus'}/>&nbsp;{props.isGenerating ? 'Generating QB PO...' : 'Generate QB PO'}
                    </button>
                    :null}
                    <button className="btn btn-secondary me-2 float-end" onClick={props.choices[0].func} disabled={props.isSaving}>
                        <i className="fas fa-arrow-left"/>&nbsp;Close
                    </button>
                </div>
            </div>
        </div>
    )
}

const ProductOrderFormModal = props => {
    const [workingOrders, setWorkingOrders] = useState(props.orderId ? null : [DEFAULT_ORDER]);
    const [savedOrders, setSavedOrders] = useState(null);
    const [modified, setModified] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [savingLabel, setSavingLabel] = useState('Saving...');
    const [isGenerating, setIsGenerating] = useState(false);
    const [saveMode, setSaveMode] = useState(props.orderId ? 'PUT' : 'POST');
    const [customers, setCustomers] = useState(null);
    const modaling = useContext(ModalContext);
    const alertsContext = useContext(AlertsContext);

    useEffect(() => {
        if(props.orderId) {
            chimera.callAPI(undefined, `/api/productorders/${props.orderId}`)
            .then(order => {
                setWorkingOrders([order]);
                setSavedOrders([order]);
            })
        }
    }, [props.orderId]);

    useEffect(() => {
        if(customers === null) {
            chimera.callAPI(undefined, '/api/customers')
            .then(allCustomers => {
                setCustomers(allCustomers.filter((customer) => customer.integrationIds.quickbooks !== ""))
            })
            .catch(err => {
                console.error(err);
                alert("ERROR: Failed to read customers; cannot continue");
                modaling.setModal(null);
            })
        }
    }, [customers]);

    const saveDisabled = () => {
        if(isSaving) return true;
        if(saveMode === 'PUT') {
            return chimera.deepEqual(workingOrders, savedOrders);
        }
        else {
            return !modified;
        }
    }

    const createPurchaseOrder = (order, qbEstimate) => {
        return new Promise((resolve, reject) => {
            const body = {
                APAccountRef: {
                    value: '41',
                    name: 'Accounts Payable (A/P)'
                },
                VendorRef: {
                    value: '916',
                    name: 'DELETE'
                },
                Line: [
                    {
                        DetailType: "ItemBasedExpenseLineDetail", 
                        Amount: 0, 
                        ItemBasedExpenseLineDetail: {
                            ItemRef: {
                                name: "DELETE", 
                                value: "842"
                            }, 
                            CustomerRef: {
                                value: order.customer.qbId
                            }, 
                            Qty: 1, 
                            UnitPrice: 0,
                        }
                    }
                ],
                CustomField: [
                    {
                        DefinitionId: '2',
                        Name: 'Chimera Number',
                        Type: 'StringType',
                        StringValue: order.bundleNumber.toString()
                    }
                ]
            }

            chimera.callQuickBooksAPI(undefined, '/api/qb/purchaseorder', 'POST', body)
            .then(async qbPurchaseOrder => {
                if(qbEstimate) {
                    // Close the estimate
                    const newEstimate = JSON.parse(JSON.stringify(qbEstimate));
                    newEstimate.TxnStatus = 'Closed';
                    try {
                        await chimera.callQuickBooksAPI(undefined, '/api/qb/estimate', 'PUT', newEstimate);
                    }
                    catch(err) {
                        console.error(err);
                        alert(`WARNING: Failed to close QB Estimate ${qbEstimate.DocNumber}, but the Purchase Order was successfully created.`);
                    }
                }
                resolve(qbPurchaseOrder);
            })
            .catch(err => {
                reject(err);
            })
        })
    }

    const generateQBPO = (orders) => {
        // Generate PO in QB for all `orders`
        // Apply QB PO details and resolve with modified orders
        return new Promise(async(resolve, reject) => {
            let newOrders = [];
            let qbPurchaseOrder;
            let qbEstimate;
            for(const order of orders) {
                // Create the QB PO, assign the number to the order, and save it with PUT
                try {
                    if(order.qbEstimateNumber !== "") {
                        // Fetch the estimate and pass it as an argument
                        qbEstimate = await chimera.callQuickBooksAPI(undefined, `/api/qb/estimate/${order.qbEstimateNumber}`);
                    }
                    qbPurchaseOrder = await createPurchaseOrder(order, qbEstimate);

                    const newOrder = JSON.parse(JSON.stringify(order));
                    if(qbPurchaseOrder) {
                        newOrder.qbPurchaseOrderNumber = qbPurchaseOrder.DocNumber;
                        newOrder.qbPurchaseOrderId = qbPurchaseOrder.Id;
                    }
                    if(qbEstimate) newOrder.qbEstimateId = qbEstimate.Id;
                    newOrders.push(newOrder);
                }
                catch(err) {
                    reject(err);
                }
            }

            resolve(newOrders);
        })
    }

    const handleGenerate = (event) => {
        event.preventDefault();
        setIsGenerating(true);
        generateQBPO(workingOrders)
        .then(newOrders => {
            setWorkingOrders(newOrders);
            setModified(true);
        })
        .catch(err => {
            console.error(err);
            alert('ERROR: QB PO generation failed; see console output for more');
        })
        .finally(() => {
            setIsGenerating(false);
        })
    }

    const handleSave = (orders) => {
        setIsSaving(true);
        setSavingLabel('Saving...');
        if(!orders) orders = workingOrders;
        return new Promise(async(resolve, reject) => {

            // Look up Invoice ID?
            let newOrders = [];
            for(let i = 0; i < orders.length; i++) {
                let newOrder = JSON.parse(JSON.stringify(orders[i]));
                let condition1 = newOrder.qbInvoiceNumber && !newOrder.qbInvoiceId;
                let condition2 = newOrder.qbInvoiceNumber && savedOrders && newOrder.qbInvoiceNumber !== savedOrders[i].qbInvoiceNumber;
                if(condition1 || condition2) {
                    setSavingLabel(`Looking up QB Invoice ${newOrder.qbInvoiceNumber}...`);
                    try {
                        const invoice = await chimera.callQuickBooksAPI(undefined, `/api/qb/invoice/${newOrder.qbInvoiceNumber}`);
                        newOrder.qbInvoiceId = invoice.Id;
                        newOrder.qbInvoiceStatus = invoice.Balance === 0 ? "Paid" : "Unpaid";
                    }
                    catch(err) {
                        reject(err);
                    }
                }
                else if(!newOrder.qbInvoiceNumber) {
                    newOrder.qbInvoiceId = '';
                }
                newOrders.push(newOrder);
            }
            orders = newOrders;
            setSavingLabel('Saving...');

            let mySavedOrders = [];
            if(saveMode === "POST") {
                try {
                    mySavedOrders = await chimera.callAPI(undefined, '/api/productorders/batch', 'POST', {orders, isEstimate: props.isEstimate});
                    if(mySavedOrders.find((order) => order.status === "New")) {
                        alertsContext.reload();
                    }
                }
                catch(err) {
                    reject(err);
                    return;
                }
            }
            else { // saveMode = "PUT"; this assumes that we are handling just 1 order and not multiple
                for(const order of orders) {
                    try {
                        mySavedOrders.push(await chimera.callAPI(undefined, `/api/productorders/${order._id}`, 'PUT', order))
                    }
                    catch(err) {
                        reject(err);
                    }
                }
                alertsContext.reload();
            }
            resolve(mySavedOrders);
        })
    }

    const saveAndCloseFunc = (resolve, reject) => {
        const dismiss = (event) => {
            event.preventDefault();
            modaling.backtrack();
        }
        modaling.setModal(<Modal choices={[]} dismiss={dismiss}>
            <LoadingSpinner size={75}/>
        </Modal>)
        handleSave()
        .then(result => resolve(result))
        .catch(err => reject(err));
    }

    const saveAndCloseResolve = (savedOrders) => {
        setWorkingOrders(savedOrders);
        setSavedOrders(savedOrders);
        setSaveMode('PUT');
        props.onClose();
        modaling.setModal(null);
    }

    const saveAndCloseReject = (err) => {
        console.error(err.createdProductOrder);
        alert(`${err.createdProductOrder ? "ERROR: The Product Order was saved, but the QuickBooks PO could not be created." : "ERROR: Failed to save the Product Order"}`);
        modaling.backtrack();
    }

    const choices = [
        choiceCancel(props, !saveDisabled(), <>
            <h3>You have unsaved changes</h3>
            <p>Are you sure you want to close this form? Your changes will not be saved.</p>
        </>, "Close", {func: saveAndCloseFunc, resolve: saveAndCloseResolve, reject: saveAndCloseReject})
    ]

    if(saveMode === 'PUT' && savedOrders !== null) {
        choices.push(choiceDelete(props, async() => {
            modaling.setModal(<Modal choices={[]} dismiss={(e) => {e.preventDefault(); modaling.setModal(null)}}>
                <LoadingSpinner size={75}/>
            </Modal>);
            for(const order of savedOrders) {
                try {
                    await chimera.callAPI(undefined, `/api/productorders/${order._id}`, 'DELETE');
                    if(props.parentBanners) props.parentBanners.addBanner('info', `Order (ID: ${order._id}) has been deleted.`, 'Success');
                }
                catch(err) {
                    console.error(err);
                    if(props.parentBanners) props.parentBanners.addBanner('danger', `Failed to delete order (ID: ${order._id})`, 'Error');
                }
            }
            props.onClose();
            modaling.setModal(null);
        }, {label: `Delete${savedOrders.length > 1 ? `(${savedOrders.length}) ` : ''} Permanently`}))
    }

    return (
        <Modal choices={[]} dismiss={choices[0].func}>
            <BannerLog>
                {workingOrders && customers && (!props.orderId || savedOrders) ? 
                <ProductOrderFormBody {...props} 
                    workingOrders={workingOrders} setWorkingOrders={setWorkingOrders} 
                    savedOrders={savedOrders} setSavedOrders={setSavedOrders}
                    modifed={modified} setModified={setModified}
                    isSaving={isSaving} setIsSaving={setIsSaving}
                    saveMode={saveMode} setSaveMode={setSaveMode}
                    saveDisabled={saveDisabled()}
                    handleSave={handleSave}
                    customers={customers}
                    choices={choices}
                    savingLabel={savingLabel} setSavingLabel={setSavingLabel}
                    isGenerating={isGenerating}
                    handleGenerate={handleGenerate}
                />
                : <LoadingSpinner size={50}/>}
            </BannerLog>
        </Modal>
    )
}

export default ProductOrderFormModal;